import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | King Electric Guitars" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>That there page you went lookin' for was not found.</h1>
          <p>I don't know what to tell you. Something's gone wrong. You went looking for something that doesn't exist. Life can be like that sometimes. Maybe try the <a href="/">homepage</a>?</p>
          <p>But if you were looking for a custom guitar that doesn't yet exist, I can rectify that. I'm in the business of making dream guitars come true.</p>
          <h4>Get in touch. Let's talk about building your dream guitar!</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
